import itineraryPlanStore from "src/store/itineraryPlanStore";
import { useEffect, useState } from "react";
import { toJS } from "mobx";
import { Property } from "src/models/models";
import ImageGalleryPopup from "./ImageGalleryPopup";
import _ from "underscore";
import { amenitiesIconMapping } from "src/constants/iconMapping";
import RoomTypesContainer from "./RoomTypesContainer";
import {
  countEndDays,
  countStartDays,
  formatPropertyDescription,
  getDateStrInYYYYMMDD,
  getValueAccomodationType,
} from "src/helpers/utils";
import PropertyOverlay from "../propertyOverlay/PropertyOverlay";
import { observer } from "mobx-react-lite";
import { useDispatch } from "react-redux";
import { getPackagePricing } from "src/store/details/actions";
import rightArrowImg from "../../assets/images/enable-right.png";
import leftArrowImg from "../../assets/images/enable-left.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CommonTooltip from "../common/CommonTooltip";
import moment from "moment";
import Rating from "@mui/material/Rating";

const PackageItineraryStays = observer(
  ({
    cityName,
    packageType,
    itineraryPlansData,
    packageId,
    loader,
  }: {
    cityName: string;
    packageType: any;
    itineraryPlansData: any[];
    packageId: any;
    loader: any;
  }) => {
    let dispatch = useDispatch();

    /* setting states here */
    const [viewMoreId, setViewMoreId] = useState("");
    const [roomList, setRoomList] = useState<any[]>([]);
    const [policyList, setPolicyList] = useState<any[]>([]);
    const [facilityLength, setFacilityLength] = useState(4);
    const [showCarousel, setShowCarousel] = useState(false);
    const [imageIndex, setImageIndex] = useState<any>(null);
    const [selectedCity, setSelectedCity] = useState<string>();
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [propertyOverlay, setPropertyOverlay] = useState(false);
    const [carouselIndex, setCarouselIndex] = useState<any[]>([]);
    const [propertyDetails, setPropertyDetails] = useState<any>({});
    const [selectRoomTypes, setSelectRoomTypes] = useState<any[]>([]);
    const [propertyLevelMsg, setPropertyLevelMsg] = useState<
      { destinationIndex: number; errorMessage: string }[]
    >([]);
    const [selectedPropertyId, setSelectedPropertyId] = useState<any>("");
    const [imageOverLayArray, setImageOverLayArray] = useState<any[]>([]);
    const [selectedProperties, setSelectedProperties] = useState<any[]>([]);
    const [singlExpandedRooms, setSingleExpandedRooms] = useState<{
      [key: string]: boolean;
    }>({});
    const [expandedDescription, setExpandedDescription] = useState<any>([]);

    const defaultRoomList = [
      { value: "", title: "Select number of rooms" },
      { value: 1, title: "1 Room" },
      { value: 2, title: "2 Rooms" },
      { value: 3, title: "3 Rooms" },
      { value: 4, title: "4 Rooms" },
    ];

    useEffect(() => {
      if (itineraryPlansData && itineraryPlansData.length > 0) {
        /** Staycation functionality here **/
        if (packageType === "packageType.Staycation") {
          if (itineraryPlanStore.calenderSelected) {
            const carouselIndexArr: any[] = [];
            toJS(itineraryPlanStore.selectedProperties).forEach(
              (stayObj: any, index) => {
                carouselIndexArr.push({ propertyIndex: index, startIndex: 0 });
                // const selectedPropertyObj = {
                //   ...stayObj.property,
                //   destinationIndex: index + 1,
                // };
                // selectedPropertiesArr.push(selectedPropertyObj);
              }
            );
            setCarouselIndex(carouselIndexArr);
            setSelectRoomTypes(toJS(itineraryPlanStore.stayRoomTypes));
            setSelectedProperties(toJS(itineraryPlanStore.selectedProperties));
          } else {
            itineraryPlanStore.setStateToInitialForm();
            executeInitialLogic();
          }
        } else {
          /** Holidays functionality here **/
          if (itineraryPlanStore.calenderSelected) {
            const carouselIndexArr: any[] = [];
            toJS(itineraryPlanStore.selectedProperties).forEach(
              (stayObj: any, index) => {
                carouselIndexArr.push({ propertyIndex: index, startIndex: 0 });
              }
            );
            setCarouselIndex(carouselIndexArr);
            setSelectRoomTypes(toJS(itineraryPlanStore.stayRoomTypes));
            setSelectedProperties(toJS(itineraryPlanStore.selectedProperties));
          } else {
            itineraryPlanStore.setStateToInitialForm();
            executeInitialLogic();
          }
        }
      }
    }, [itineraryPlansData]);

    const executeInitialLogic = () => {
      const propertyListArr: Property[] = [];
      const stayRoomTypesArr: any[] = [];
      const selectedPropertiesArr: any[] = [];
      const carouselIndexArr: any[] = [];
      itineraryPlansData.forEach((stayObj: any, index) => {
        carouselIndexArr.push({ propertyIndex: index, startIndex: 0 });
        const propertyListObj: Property = {
          id: stayObj.id,
          dayIndexFrom: stayObj.dayIndexFrom,
          dayIndexTo: stayObj.dayIndexTo,
          propertyId: stayObj.propertyId,
          propertyName: stayObj.propertyName,
          roomTypeId: stayObj.roomTypeId,
          roomTypeName: stayObj.roomTypeName,
          destination: stayObj.destination,
          cityId: stayObj.cityId,
          mealPlan: "",
          numRooms: stayObj.numRooms,
          fromDate: stayObj.fromDate,
          toDate: stayObj.toDate,
          notes: stayObj.notes,
          destinationIndex: index + 1,
        };

        const stayRoomTypeObj = {
          propertyId: stayObj.propertyId,
          roomId: stayObj.roomTypeId,
          roomName: stayObj.roomTypeName,
          mealPlan: "",
          rateClass: "",
          destinationIndex: index + 1,
          packageType: packageType,
        };

        const selectedPropertyObj = {
          ...stayObj.property,
          destinationIndex: index + 1,
        };

        propertyListArr.push(propertyListObj);
        stayRoomTypesArr.push(stayRoomTypeObj);
        selectedPropertiesArr.push(selectedPropertyObj);
      });
      setCarouselIndex(carouselIndexArr);
      setSelectRoomTypes(stayRoomTypesArr);
      setSelectedProperties(selectedPropertiesArr);
      itineraryPlanStore.setPropertyList(propertyListArr);
      itineraryPlanStore.setStayRoomTypes(stayRoomTypesArr);
      itineraryPlanStore.setSelectedProperties(selectedPropertiesArr);
    };

    useEffect(() => {
      if (propertyDetails && Object.keys(propertyDetails).length > 0) {
        const filteredSelectedProperties = toJS(
          itineraryPlanStore.selectedProperties
        ).filter((property) => property.destinationIndex !== selectedIndex);

        filteredSelectedProperties.push({
          ...propertyDetails,
          destinationIndex: selectedIndex,
        });

        filteredSelectedProperties.sort(
          (a, b) => a.destinationIndex - b.destinationIndex
        );

        itineraryPlanStore.setSelectedProperties(filteredSelectedProperties);
        let carouselIndexArr: any[] = [];
        filteredSelectedProperties.map((item: any, index) =>
          carouselIndexArr.push({ propertyIndex: index, startIndex: 0 })
        );
        setSelectedProperties(filteredSelectedProperties);
        loader(false);

        if (itineraryPlanStore.calenderSelected) {
          makeAvailabilityCall();
        }
      }
    }, [propertyDetails]);

    useEffect(() => {
      if (itineraryPlanStore.availabilityData) {
        const roomList: any[] = [];
        const propertyListArr: Property[] = [];
        const stayRoomsArr: any[] = [];
        const policyDataLists: any[] = [];
        const propertyMessageArr: {
          destinationIndex: number;
          errorMessage: string;
        }[] = [];

        if (itineraryPlanStore?.availabilityData?.propertyPricing?.length > 0) {
          itineraryPlanStore?.availabilityData?.propertyPricing?.forEach(
            (element: any, propertyIndex: any) => {
              element?.room?.forEach((ele: any) => {
                roomList.push({
                  propertyId: element.propertyId,
                  roomId: ele.roomTypeId,
                  value: ele.numRooms,
                  title: ele.numRooms + " Room",
                  destinationIndex: propertyIndex + 1,
                });

                const propertyListObj: Property =
                  itineraryPlanStore.propertyList.find(
                    (property) =>
                      property.destinationIndex === propertyIndex + 1
                  )!;

                propertyListArr.push({
                  ...propertyListObj,
                  mealPlan: ele.mealPlan,
                  numRooms: ele.numRooms,
                });

                const stayRoomTypeObj = itineraryPlanStore.stayRoomTypes.find(
                  (stayRoom) => stayRoom.destinationIndex === propertyIndex + 1
                )!;

                stayRoomsArr.push({
                  ...stayRoomTypeObj,
                  mealPlan: ele.mealPlan,
                  rateClass: ele.rateClass,
                });

                policyDataLists.push({
                  text: compareDates(
                    ele?.priceData?.typeWisePriceBreakDown[0]
                      ?.roomDistribution[0]?.cancellationPolicies[0]
                      ?.ifCancelledBy
                  )
                    ? compareDates(
                        ele?.priceData?.typeWisePriceBreakDown[0]
                          ?.roomDistribution[0]?.cancellationPolicies[0]
                          ?.ifCancelledBy
                      )
                    : "",
                  roomId: ele.roomTypeId,
                  destinationIndex: propertyIndex + 1,
                });

                if (ele?.errorMessage) {
                  if (ele?.displayMessage) {
                    propertyMessageArr.push({
                      destinationIndex: propertyIndex + 1,
                      errorMessage: ele?.displayMessage,
                    });
                  } else {
                    propertyMessageArr.push({
                      destinationIndex: propertyIndex + 1,
                      errorMessage:
                        "No inventory found for this property, choose different dates or room types",
                    });
                  }
                }
              });
            }
          );

          itineraryPlanStore.setPropertyList(propertyListArr);
          itineraryPlanStore.setStayRoomTypes(stayRoomsArr);
          setSelectRoomTypes(stayRoomsArr);
          setPolicyList(policyDataLists);
          itineraryPlanStore.setpolicyLists(policyDataLists);
          setRoomList(roomList);
          setPropertyLevelMsg(propertyMessageArr);
        }
      }
    }, [itineraryPlanStore.availabilityData]);

    const singleToggleAmenities = (roomId: string) => {
      setSingleExpandedRooms((prev) => ({
        ...prev,
        [roomId]: !prev[roomId],
      }));
    };

    const prevImages = (pIndex: number) => {
      let existingCarouselIndex = carouselIndex.filter(
        (item: any) => item.propertyIndex !== pIndex
      );
      let newCarouselIndex = carouselIndex.find(
        (item: any) => item.propertyIndex === pIndex
      );

      if (newCarouselIndex?.startIndex > 0) {
        newCarouselIndex.startIndex = newCarouselIndex?.startIndex - 3;
        existingCarouselIndex.push(newCarouselIndex);
      }
      setCarouselIndex(existingCarouselIndex);
    };

    const nextImages = (carouselImagesLength: any, pIndex: number) => {
      let existingCarouselIndex = carouselIndex.filter(
        (item: any) => item.propertyIndex !== pIndex
      );
      let newCarouselIndex = carouselIndex.find(
        (item: any) => item.propertyIndex === pIndex
      );

      if (newCarouselIndex?.startIndex + 3 < carouselImagesLength) {
        newCarouselIndex.startIndex = newCarouselIndex?.startIndex + 3;
        existingCarouselIndex.push(newCarouselIndex);
      }
      setCarouselIndex(existingCarouselIndex);
    };

    const makeAvailabilityCall = () => {
      const data: any[] = [];
      const startDate = itineraryPlanStore.rightContainerData.fromDate;
      const endDate = itineraryPlanStore.rightContainerData.toDate;

      let toDate = new Date(startDate).setHours(0, 0, 0, 0);
      let fromDate = new Date(startDate).setHours(0, 0, 0, 0);
      if (itineraryPlansData && itineraryPlansData.length > 0) {
        itineraryPlanStore.stayRoomTypes.forEach((stayRoomObj: any) => {
          let dayIndexFrom =
            itineraryPlansData[stayRoomObj.destinationIndex - 1]?.dayIndexFrom;
          let dayIndexTo =
            itineraryPlansData[stayRoomObj.destinationIndex - 1]?.dayIndexTo;

          fromDate = toDate;
          toDate =
            packageType === "packageType.Staycation"
              ? endDate
              : new Date(
                  new Date(fromDate).setDate(
                    new Date(fromDate).getDate() +
                      (dayIndexTo - dayIndexFrom) +
                      1
                  )
                ).setHours(0, 0, 0, 0);

          data.push({
            propertyId: stayRoomObj.propertyId,
            roomTypeId: stayRoomObj.roomId,
            mealPlan: stayRoomObj.mealPlan,
            rateClass: stayRoomObj.rateClass,
            fromDate: getDateStrInYYYYMMDD(new Date(fromDate)),
            toDate: getDateStrInYYYYMMDD(new Date(toDate)),
            numRooms:
              itineraryPlanStore.propertyList.find(
                (property) =>
                  property.destinationIndex === stayRoomObj.destinationIndex
              )?.numRooms ?? 1,
            numAdults: itineraryPlanStore.rightContainerData.numAdults,
            numChildren: itineraryPlanStore.rightContainerData.numChildren,
            childAges: itineraryPlanStore.rightContainerData.childAges,
          });
        });

        itineraryPlanStore.setavailabilitypayload(data);
        dispatch(getPackagePricing(data, "", "", packageId));
      }
    };

    const updateShowImageGalleryPopup = (data: any) => {
      setShowCarousel(data);
    };

    const moreFacilitylist = (total: number, propertyId: any) => {
      setFacilityLength(total);
      if (total === 4) {
        setViewMoreId("");
      } else {
        setViewMoreId(propertyId);
      }
    };

    const getIcon = (text: string) => {
      const textVar = text?.toLowerCase();
      let bestMatch = "";
      let bestMatchIndex = 0;
      let matchingIcons = amenitiesIconMapping.filter((icon: any) =>
        textVar?.includes(icon.keyword)
      );
      for (let i = 0; i < matchingIcons.length; i++) {
        if (matchingIcons[i].keyword !== undefined) {
          if (matchingIcons[i].keyword.length > bestMatch.length) {
            bestMatch = matchingIcons[i].keyword;
            bestMatchIndex = i;
          }
        }
      }
      if (matchingIcons.length === 0)
        return require(`../../assets/images/icons/highlights/default.png`);
      return require(`../../assets/images/icons/amenities/${matchingIcons[bestMatchIndex].icon}`);
    };

    const getPropertyImagesArray = (
      mediaFolderFiles: any,
      mediaFolderUrl: string
    ) => {
      const imageArray: any[] = [];
      mediaFolderFiles?.map((file: any) => {
        const tempImg = {
          imageUrl: `https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_S3_BUCKET}/property/${mediaFolderUrl}/${file?.fileName}`,
          imageCaption: file.fileName,
        };
        imageArray.push(tempImg);
      });
      const mediaOverLayArray = [
        {
          collectionType: "Additional",
          propertyId: null,
          roomTypeId: null,
          images: imageArray,
        },
      ];

      setImageOverLayArray(mediaOverLayArray);
    };

    const handleRoomTypeSelect = (
      id: any,
      name: any,
      propertyId: any,
      destinationIndex: number
    ) => {
      const findRoomType = itineraryPlanStore.stayRoomTypes.find(
        (room) => room.destinationIndex === destinationIndex
      );

      if (findRoomType) {
        const deleteRoomTypeFromStore = itineraryPlanStore.stayRoomTypes.filter(
          (room) => room.destinationIndex !== destinationIndex
        );

        const roomTypeObj = {
          propertyId: propertyId,
          roomId: id,
          roomName: name,
          mealPlan: "",
          rateClass: "",
          destinationIndex: destinationIndex,
        };

        deleteRoomTypeFromStore.push(roomTypeObj);

        deleteRoomTypeFromStore.sort(
          (a, b) => a.destinationIndex! - b.destinationIndex!
        );

        setSelectRoomTypes(deleteRoomTypeFromStore);
        itineraryPlanStore.setStayRoomTypes(deleteRoomTypeFromStore);

        const propertyListObj: Property = itineraryPlanStore.propertyList.find(
          (property) => property.destinationIndex === destinationIndex
        )!;

        const filteredPropertyList = itineraryPlanStore.propertyList.filter(
          (property) => property.destinationIndex !== destinationIndex
        );

        filteredPropertyList.push({
          ...propertyListObj,
          roomTypeId: id,
          roomTypeName: name,
          mealPlan: "",
          numRooms: 1,
        });

        filteredPropertyList.sort(
          (a, b) => a.destinationIndex! - b.destinationIndex!
        );

        itineraryPlanStore.setPropertyList(filteredPropertyList);

        if (itineraryPlanStore.calenderSelected) {
          makeAvailabilityCall();
        }
      }
    };

    const handleMealPlanChange = (
      destinationIndex: number,
      mealPlanObj: string
    ) => {
      if (mealPlanObj) {
        const mealPlanSplit = mealPlanObj.split("-");

        const findRoomType = itineraryPlanStore.stayRoomTypes.find(
          (room) => room.destinationIndex === destinationIndex
        );

        if (findRoomType) {
          const deleteRoomTypeFromStore =
            itineraryPlanStore.stayRoomTypes.filter(
              (room) => room.destinationIndex !== destinationIndex
            );

          const roomTypeObj = {
            ...findRoomType,
            mealPlan: mealPlanSplit[0],
            rateClass: mealPlanSplit[1],
            destinationIndex: destinationIndex,
          };

          deleteRoomTypeFromStore.push(roomTypeObj);
          deleteRoomTypeFromStore.sort(
            (a, b) => a.destinationIndex! - b.destinationIndex!
          );

          setSelectRoomTypes(deleteRoomTypeFromStore);
          itineraryPlanStore.setStayRoomTypes(deleteRoomTypeFromStore);

          const propertyListObj: Property =
            itineraryPlanStore.propertyList.find(
              (property) => property.destinationIndex === destinationIndex
            )!;

          const filteredPropertyList = itineraryPlanStore.propertyList.filter(
            (property) => property.destinationIndex !== destinationIndex
          );

          filteredPropertyList.push({
            ...propertyListObj,
            mealPlan: mealPlanSplit[0],
          });

          filteredPropertyList.sort(
            (a, b) => a.destinationIndex! - b.destinationIndex!
          );

          itineraryPlanStore.setPropertyList(filteredPropertyList);

          makeAvailabilityCall();
        }
      } else {
        return;
      }
    };

    const handleRoomNumberChange = (
      destinationIndex: number,
      roomCount: number
    ) => {
      if (!isNaN(roomCount)) {
        const updatePropertyObj: Property =
          itineraryPlanStore.propertyList.find(
            (propertyObj) => propertyObj.destinationIndex === destinationIndex
          )!;

        const filteredPropertyArr = itineraryPlanStore.propertyList.filter(
          (propertyObj) => propertyObj.destinationIndex !== destinationIndex
        );

        filteredPropertyArr.push({
          ...updatePropertyObj,
          numRooms: roomCount,
        });

        filteredPropertyArr.sort(
          (a, b) => a.destinationIndex! - b.destinationIndex!
        );

        itineraryPlanStore.setPropertyList(filteredPropertyArr);
        makeAvailabilityCall();
      } else {
        return;
      }
    };

    const getCheckInCheckOutDates = (fromIndex: any, toIndex: any) => {
      let nnights: any = "";
      let totalStays: any = "";
      nnights = toIndex - fromIndex;

      totalStays = `${nnights + 1}N ${nnights + 2}D`;
      let datesstays: any = "";

      if (!itineraryPlanStore.calenderSelected) {
        datesstays = "";
        return totalStays;
      } else {
        datesstays =
          countStartDays(
            getDateStrInYYYYMMDD(
              new Date(itineraryPlanStore.rightContainerData.fromDate)
            ),
            fromIndex
          ) +
          " to " +
          countEndDays(
            getDateStrInYYYYMMDD(
              new Date(itineraryPlanStore.rightContainerData.fromDate)
            ),
            toIndex
          );
        return totalStays + " - " + datesstays;
      }
    };

    const getCheckInCheckOutDatesForStayCations = (
      fromDate: any,
      toDate: any
    ) => {
      const from = moment(fromDate);
      const to = moment(toDate);

      const numberOfNights = to.diff(from, "days");
      const numberOfDays = numberOfNights + 1;

      const formattedFromDate = from.format("DD-MM-YYYY");
      const formattedToDate = to.format("DD-MM-YYYY");

      const dateSentence = `${numberOfNights}N ${numberOfDays}D - ${formattedFromDate} to ${formattedToDate}`;
      return dateSentence;
    };

    const getMealPlanRatePlanMapping = (
      roomId: any,
      destinationIndex: number
    ) => {
      let mappings: any[] = [];
      if (
        itineraryPlanStore.calenderSelected &&
        itineraryPlanStore?.availabilityData?.propertyPricing?.length > 0
      ) {
        itineraryPlanStore?.availabilityData?.propertyPricing?.forEach(
          (element: any, propertyIndex: number) => {
            if (propertyIndex === destinationIndex - 1) {
              element?.room?.forEach((elementRoom: any) => {
                if (elementRoom.roomTypeId === roomId && !elementRoom.error) {
                  element.availableMealPlan.forEach((ele: any) => {
                    mappings.push({
                      value: ele.mealPlan + "-" + ele.rateClass,
                      title: ele.mealPlanDesc + "-" + ele.rateClassDesc,
                    });
                  });
                } else if (
                  elementRoom.roomTypeId === roomId &&
                  elementRoom.error
                ) {
                  element.availableMealPlan.forEach((ele: any) => {
                    mappings.push({
                      value: "-",
                      title: "-",
                    });
                  });
                }
              });
            }
          }
        );
      }

      if (mappings.length === 0) {
        mappings.push({
          value: "-",
          title: "Choose a meal plan",
        });
      }

      const inventoryFailed = mappings.find(
        (x) => x.value === "-" && x.title === "-"
      );

      if (inventoryFailed) {
        mappings = [];
        mappings.push({
          value: "-",
          title: "No Inventory Available",
        });
      }
      return mappings;
    };

    const getSelectedRoomCount = (destinationIndex: any, roomId: any) => {
      let roomCount = 0;
      if (roomList?.length > 0) {
        roomList?.forEach((ele: any, i: any) => {
          if (
            ele.destinationIndex === destinationIndex &&
            ele.roomId === roomId
          ) {
            roomCount = ele.value;
          }
        });
      }
      return roomCount;
    };

    const getMealPlanValueForRoom = (destinationIndex: number) => {
      const findRoom = itineraryPlanStore.stayRoomTypes.find(
        (stayRoom) => stayRoom.destinationIndex === destinationIndex
      );

      if (findRoom && findRoom.mealPlan && findRoom.rateClass) {
        return `${findRoom.mealPlan}-${findRoom.rateClass}`;
      } else {
        return "";
      }
    };

    const compareDates = (policyDate: any) => {
      let result = "";
      if (policyDate) {
        const planPolicyDate = policyDate?.split("T")[0];
        const date = new Date();
        const todaydate: any = getDateStrInYYYYMMDD(date);

        if (todaydate > planPolicyDate) {
          result = "Booking is non-refundable";
        } else {
          result =
            "Free Cancellation Upto " +
            planPolicyDate?.split("-")[2] +
            "-" +
            planPolicyDate?.split("-")[1] +
            "-" +
            planPolicyDate?.split("-")[0];
        }
      } else {
        result = "";
      }
      return result;
    };

    // toggle the property of the description
    const toggleExpansion = (index: any) => {
      setExpandedDescription((prev: any) =>
        prev.includes(index)
          ? prev.filter((i: any) => i !== index)
          : [...prev, index]
      );
    };

    return (
      <div className="pdp-stays-section">
        {selectedProperties &&
          selectedProperties.length > 0 &&
          selectedProperties.map((property, propertyIndex: number) => (
            <div
              key={propertyIndex}
              className="stays-section"
              id={`pdp_stays_${propertyIndex}`}
            >
              <div className="property-stay-section">
                <div className="property-stay-img-div">
                  {property?.mediaFolder?.files.length > 0 && (
                    <div className="property-stay-thumbnail">
                      <img
                        className="property-stay-img"
                        src={`https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_S3_BUCKET}/property/${property?.mediaFolder?.url}/${property?.mediaFolder?.files[0]?.fileName}`}
                      />
                      <button
                        className="carousel-toggle-button"
                        id={`pdp_btnViewMore_${propertyIndex}`}
                        onClick={() => {
                          setImageIndex(0);
                          setShowCarousel(!showCarousel);
                          getPropertyImagesArray(
                            property?.mediaFolder?.files,
                            property?.mediaFolder?.url
                          );
                        }}
                      >
                        View more...
                      </button>
                    </div>
                  )}
                  <div className="carousel-container">
                    <button
                      className="carousel-arrow left"
                      id={`pdp_btnPrevImage_${propertyIndex}`}
                      disabled={
                        carouselIndex.find(
                          (x) => x.propertyIndex === propertyIndex
                        )?.startIndex == 0
                      }
                      onClick={() => {
                        prevImages(propertyIndex);
                      }}
                    >
                      <img
                        src={leftArrowImg}
                        alt="right-arrow"
                        style={{ height: "12px" }}
                      />
                    </button>

                    <div className="carousel-images">
                      {property?.mediaFolder?.files.length > 0 &&
                        property?.mediaFolder?.files
                          .slice(
                            carouselIndex.find(
                              (x) => x.propertyIndex === propertyIndex
                            )?.startIndex,
                            carouselIndex.find(
                              (x) => x.propertyIndex === propertyIndex
                            )?.startIndex + 3
                          )
                          .map((file: any, index: any) => {
                            return (
                              <img
                                key={index}
                                src={`https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_S3_BUCKET}/property/${property?.mediaFolder?.url}/${file?.fileName}`}
                                alt={file.fileName}
                                id={`pdp_imgCaraousel_${propertyIndex}-${index}`}
                                onClick={(e) => {
                                  const imgIndex =
                                    property?.mediaFolder?.files?.findIndex(
                                      (fileObj: { fileName: any }) =>
                                        fileObj.fileName === file.fileName
                                    );
                                  setImageIndex(imgIndex);
                                  setShowCarousel(!showCarousel);
                                  getPropertyImagesArray(
                                    property?.mediaFolder?.files,
                                    property?.mediaFolder?.url
                                  );
                                }}
                              />
                            );
                          })}
                    </div>

                    <button
                      className="carousel-arrow right"
                      id={`pdp_btnNextImage_${propertyIndex}`}
                      disabled={
                        carouselIndex.find(
                          (x) => x.propertyIndex === propertyIndex
                        )?.startIndex +
                          3 >=
                        property?.mediaFolder?.files.length
                      }
                      onClick={() => {
                        nextImages(
                          property?.mediaFolder?.files.length,
                          propertyIndex
                        );
                      }}
                    >
                      <img
                        src={rightArrowImg}
                        alt="right-arrow"
                        style={{ height: "12px" }}
                      />
                    </button>
                  </div>
                </div>
                <div className="property-stay-content-button">
                  <div className="property-stay-div">
                    <div className="property-stay-header-div">
                      <div className="property-stay-header-txt test">
                        <h1 className="title">{property?.name}</h1>
                        <div>
                          {packageType === "packageType.Staycation" ? (
                            ""
                          ) : (
                            <button
                              className="property-stay-header-btn"
                              id={`pdp_btnSelect_${propertyIndex}`}
                              onClick={() => {
                                setSelectedPropertyId(property.id);
                                setSelectedCity(property.city.name);
                                setPropertyOverlay(!propertyOverlay);
                                setSelectedIndex(property.destinationIndex);
                              }}
                            >
                              Select
                            </button>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "12px",
                          flexWrap:'wrap'
                        }}
                      >
                        {itineraryPlansData &&
                          itineraryPlansData.length > 0 && (
                            <div
                              className="city-date-div"
                              style={{
                                marginTop:
                                  property?.type !== null &&
                                  property?.type !== ""
                                    ? "4px"
                                    : "0px",
                              }}
                            >
                              {property?.city?.name
                                ? property?.city?.name + " - "
                                : ""}
                              {packageType === "packageType.Staycation" &&
                              itineraryPlanStore.rightContainerData.fromDate !==
                                "" &&
                              itineraryPlanStore.rightContainerData.toDate !==
                                ""
                                ? getCheckInCheckOutDatesForStayCations(
                                    itineraryPlanStore.rightContainerData
                                      .fromDate,
                                    itineraryPlanStore.rightContainerData.toDate
                                  )
                                : getCheckInCheckOutDates(
                                    itineraryPlansData[propertyIndex]
                                      ?.dayIndexFrom,
                                    itineraryPlansData[propertyIndex]
                                      ?.dayIndexTo
                                  )}
                            </div>
                          )}
                        {property?.type !== null && property?.type !== "" && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            {(() => {
                              const formattedDescription =
                                formatPropertyDescription(property?.type);

                              if (!formattedDescription) return null;

                              const descriptionText =
                                formattedDescription.length > 1
                                  ? formattedDescription.replace(/\s\d+$/, "")
                                  : null;

                              const ratingValue =
                                formattedDescription.length === 1
                                  ? Number(formattedDescription)
                                  : Number(
                                      formattedDescription.match(/\d+/)?.[0]
                                    ) || 0;

                              return (
                                <>
                                  {descriptionText && (
                                    <span style={{ fontSize: "14px" }}>
                                      {descriptionText}
                                    </span>
                                  )}
                                  <Rating value={ratingValue} readOnly />
                                </>
                              );
                            })()}
                          </div>
                        )}
                      </div>
                      <div className="property-stay-details-location-date">
                        <div className="property-stay-details-bubble">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 4,
                            }}
                          >
                            <LocationOnIcon
                              sx={{ height: "16px", color: "#7f7f7f" }}
                            />
                            <p
                              className="stay-location-date-label"
                              style={{ marginTop: 0, flexShrink: 0 }}
                            >
                              location:
                            </p>
                          </div>
                          <span className="stay-location-date-div">
                            {property?.address}
                          </span>
                        </div>

                        {property?.accommodationType &&
                          property?.accommodationType !== null &&
                          property?.accommodationType !== "" && (
                            <div
                              className="property-stay-details-bubble"
                              style={{ padding: "3px 12px" }}
                            >
                              <span className="pckg-type">
                                Type:{" "}
                                {getValueAccomodationType(
                                  property?.accommodationType
                                )}
                              </span>
                            </div>
                          )}
                      </div>
                    </div>
                    {property?.description && (
                      <div
                        style={{ textAlign: "justify" }}
                        className="property-stay-description test"
                        dangerouslySetInnerHTML={{
                          __html: expandedDescription.includes(propertyIndex)
                            ? property?.description
                            : property?.description.substring(0, 350),
                        }}
                      ></div>
                    )}
                    {property?.description?.length > 350 && (
                      <button
                        style={{
                          alignSelf: "flex-start",
                          fontSize: "14px",
                          color: "#2e8b98",
                          cursor: "pointer",
                          border: "none",
                          backgroundColor: "transparent",
                          padding: "3px 0px",
                        }}
                        id={`pdp_btnDescription_${propertyIndex}`}
                        onClick={() => toggleExpansion(propertyIndex)}
                      >
                        {expandedDescription.includes(propertyIndex)
                          ? "View less"
                          : "View more"}
                      </button>
                    )}
                    {!property?.description && (
                      <div
                        style={{ width: "583px" }}
                        className="property-stay-description test"
                      ></div>
                    )}
                  </div>
                </div>
                {showCarousel && (
                  <ImageGalleryPopup
                    updateShowImageGalleryPopup={updateShowImageGalleryPopup}
                    showImageGalleryPopup={showCarousel}
                    packageData={imageOverLayArray}
                    imageIndex={imageIndex}
                  />
                )}
              </div>
              <div className="property-facilities">
                <ul className="inline-block">
                  {_.compact(property?.facilities).length > 0 &&
                    _.compact(property?.facilities)
                      ?.slice(
                        0,
                        viewMoreId === property.id ? facilityLength : 4
                      )
                      .map((item: any, index: number) => {
                        return (
                          <li
                            style={{ fontSize: "14px" }}
                            key={`package_hotel_facilities_${index}`}
                          >
                            <span style={{ width: "20px" }}>
                              <img
                                src={getIcon(item?.name)}
                                alt=""
                                className="img-fluid"
                              />
                            </span>
                            {item?.name}&nbsp;
                            <CommonTooltip
                              title={`${
                                item?.extractedJson?.toBePaid
                                  ? "This facility can have an additional charge to be paid at the property"
                                  : ""
                              }`}
                              arrow
                            >
                              <span>
                                {item?.extractedJson?.toBePaid && " ($)"}
                              </span>
                            </CommonTooltip>
                          </li>
                        );
                      })}
                  {_.compact(property?.facilities).length === 0 && (
                    <li key={`package_hotel_facilities_na`}>{"Not Listed"}</li>
                  )}
                  {property?.mediaFolder?.files?.length === facilityLength &&
                  property?.id === viewMoreId &&
                  property?.facilities?.length > 4 ? (
                    <li
                      style={{
                        fontSize: "14px",
                        cursor: "pointer",
                        color: "#2e8b98",
                      }}
                      id={`pdp_btnFacilitiesLess_${propertyIndex}`}
                      onClick={() => {
                        moreFacilitylist(4, property?.id);
                      }}
                    >
                      view less
                    </li>
                  ) : property?.facilities?.length > 4 ? (
                    <li
                      style={{
                        fontSize: "14px",
                        cursor: "pointer",
                        color: "#2e8b98",
                      }}
                      id={`pdp_btnFacilitiesMore_${propertyIndex}`}
                      onClick={() => {
                        moreFacilitylist(
                          property?.mediaFolder?.files?.length,
                          property.id
                        );
                      }}
                    >
                      view more
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
              <div className="property-availability-msg">
                {propertyLevelMsg.find(
                  (message) => message.destinationIndex === propertyIndex + 1
                )?.errorMessage ?? ""}
              </div>
              <div className="room-child-wrapper">
                {property?.roomTypes?.length > 0 ? (
                  property?.roomTypes?.map((room: any, index: any) => {
                    if (room?.statusCode === "roomTypeStatus.Active") {
                      const imgArr: any[] = [];
                      room.mediaFolder.files.map((file: any) => {
                        const tempImg = {
                          imageCaption: file.fileName,
                          imageUrl: `https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_S3_BUCKET}/property/${property?.mediaFolder?.url}/room/${room?.mediaFolder?.url}/${file?.fileName}`,
                        };
                        imgArr.push(tempImg);
                      });

                      return (
                        <div key={index + property.id}>
                          <div className="room-info">
                            <div className="radio-img-carosel">
                              <div className="roomselection-radiobtn">
                                <input
                                  checked={
                                    selectRoomTypes.find(
                                      (x: any) =>
                                        x.roomId === room?.id &&
                                        x.destinationIndex ===
                                          property.destinationIndex
                                    )
                                      ? true
                                      : false
                                  }
                                  id={`pdp_chkRoomSelect_${propertyIndex}_${index}`}
                                  onChange={() => {
                                    handleRoomTypeSelect(
                                      room.id,
                                      room.name,
                                      property.id,
                                      property.destinationIndex
                                    );
                                  }}
                                  name={property.destinationIndex + room.id}
                                  type="radio"
                                />
                              </div>
                              <div className="roomimagecontainer">
                                <RoomTypesContainer imageArr={imgArr} />
                              </div>
                            </div>
                            <div className="room-details-div">
                              <div className="room-header">{room.name}</div>
                              <div className="room-details-text">
                                {room?.beds[0]?.count === undefined
                                  ? ""
                                  : room?.beds[0]?.count + "-"}
                                {room?.beds[0]?.description === undefined
                                  ? ""
                                  : room?.beds[0]?.description !== undefined
                                  ? room?.beds[0]?.description?.split(
                                      "bed"
                                    )[0] + "bed"
                                  : ""}
                              </div>
                              <div className="facilities-room-div">
                                {room.amenities
                                  .slice(
                                    0,
                                    singlExpandedRooms[room.id] || false
                                      ? room.amenities.length
                                      : 4
                                  )
                                  .map(
                                    (
                                      amenity: {
                                        name: string;
                                        amenityType: string;
                                        extractedJson: any;
                                      },
                                      index: number
                                    ) => {
                                      return (
                                        <>
                                          <span
                                            key={`package_room_amenities_${index}`}
                                            style={{
                                              width: "14px",
                                            }}
                                          >
                                            <img
                                              src={getIcon(amenity.amenityType)}
                                              alt=""
                                              className="img-fluid"
                                            />
                                          </span>
                                          <span>{amenity.name}&nbsp;</span>
                                          <CommonTooltip
                                            title={`${
                                              amenity?.extractedJson?.toBePaid
                                                ? "This amenity can have an additional charge to be paid at the property"
                                                : ""
                                            }`}
                                            arrow
                                          >
                                            <span>
                                              {amenity?.extractedJson
                                                ?.toBePaid && " ($)"}
                                            </span>
                                          </CommonTooltip>
                                        </>
                                      );
                                    }
                                  )}
                                {room.amenities.length > 4 && (
                                  <button
                                    id={`pdp_btnAmenities_${propertyIndex}_${index}`}
                                    style={{
                                      fontSize: "14px",
                                      cursor: "pointer",
                                      color: "#2e8b98",
                                      border: "none",
                                      backgroundColor: "transparent",
                                      padding: "0",
                                    }}
                                    onClick={() =>
                                      singleToggleAmenities(room.id)
                                    }
                                  >
                                    {singlExpandedRooms[room.id] || false
                                      ? "View less"
                                      : "View more"}
                                  </button>
                                )}
                              </div>
                            </div>
                            <div className="meal-wrapper">
                              <div className="room-count-div">
                                <div className="dropdown-label">Meals:</div>
                                {!itineraryPlanStore.calenderSelected ? (
                                  <select
                                    disabled={
                                      !itineraryPlanStore.calenderSelected
                                    }
                                    className="room-type-dropdown"
                                    id={`pdp_selMeals_${propertyIndex}_${index}`}
                                  >
                                    <option value="">
                                      Please select travel dates
                                    </option>
                                  </select>
                                ) : (
                                  <select
                                    disabled={
                                      !selectRoomTypes.find(
                                        (x: any) =>
                                          x.roomId === room?.id &&
                                          x.destinationIndex ===
                                            property.destinationIndex
                                      )
                                        ? true
                                        : false &&
                                          !itineraryPlanStore.calenderSelected
                                        ? true
                                        : false
                                    }
                                    className="room-type-dropdown s"
                                    id={`pdp_selMeals_${propertyIndex}_${index}`}
                                    value={getMealPlanValueForRoom(
                                      property.destinationIndex
                                    )}
                                    onChange={(event) => {
                                      handleMealPlanChange(
                                        property.destinationIndex,
                                        event.target.value
                                      );
                                    }}
                                  >
                                    {getMealPlanRatePlanMapping(
                                      room.id,
                                      property.destinationIndex
                                    )?.map((e: any) => {
                                      return (
                                        <option
                                          value={e?.value}
                                          id={`pdp_optMeal_${propertyIndex}_${index}_${e?.value}`}
                                        >
                                          {e?.title}
                                        </option>
                                      );
                                    })}
                                  </select>
                                )}
                              </div>
                              <div className="room-count-div">
                                <div className="dropdown-label">Rooms:</div>
                                {!itineraryPlanStore.calenderSelected ? (
                                  <select
                                    className="room-type-dropdown"
                                    disabled={
                                      !itineraryPlanStore.calenderSelected
                                    }
                                    id={`pdp_selRooms_${propertyIndex}_${index}`}
                                  >
                                    <option value="">
                                      Select number of rooms
                                    </option>
                                  </select>
                                ) : selectRoomTypes.find(
                                    (x: any) =>
                                      x.roomId === room?.id &&
                                      x.destinationIndex ===
                                        property.destinationIndex
                                  ) &&
                                  !getMealPlanRatePlanMapping(
                                    room.id,
                                    property.destinationIndex
                                  ).find(
                                    (x) =>
                                      x.value ===
                                      getMealPlanValueForRoom(
                                        property.destinationIndex
                                      )
                                  ) ? (
                                  <select className="room-type-dropdown">
                                    <option value="">
                                      No inventory Available
                                    </option>
                                  </select>
                                ) : (
                                  <select
                                    className="room-type-dropdown"
                                    id={`pdp_selRooms_${propertyIndex}_${index}`}
                                    disabled={
                                      !selectRoomTypes.find(
                                        (x: any) =>
                                          x.roomId === room?.id &&
                                          x.destinationIndex ===
                                            property.destinationIndex
                                      )
                                        ? true
                                        : false &&
                                          !itineraryPlanStore.calenderSelected
                                        ? true
                                        : false
                                    }
                                    onChange={(event) => {
                                      handleRoomNumberChange(
                                        property.destinationIndex,
                                        parseInt(event.target.value)
                                      );
                                    }}
                                  >
                                    {defaultRoomList?.map(
                                      (list: any, i: any) => {
                                        if (
                                          list.value &&
                                          selectRoomTypes.find(
                                            (x: any) =>
                                              x.roomId === room?.id &&
                                              x.destinationIndex ===
                                                property.destinationIndex
                                          )
                                        ) {
                                          return (
                                            <option
                                              selected={
                                                getSelectedRoomCount(
                                                  property.destinationIndex,
                                                  room.id
                                                ) === list.value
                                              }
                                              value={list.value}
                                              id={`pdp_optRooms_${propertyIndex}_${index}-${list.value}`}
                                            >
                                              {list.title}
                                            </option>
                                          );
                                        } else if (
                                          list.value &&
                                          !selectRoomTypes.find(
                                            (x: any) =>
                                              x.roomId === room?.id &&
                                              x.destinationIndex ===
                                                property.destinationIndex
                                          )
                                        ) {
                                          return (
                                            <option>
                                              Select number of rooms
                                            </option>
                                          );
                                        } else {
                                          return <></>;
                                        }
                                      }
                                    )}
                                  </select>
                                )}
                              </div>
                              {!(
                                selectRoomTypes.find(
                                  (x: any) =>
                                    x.roomId === room?.id &&
                                    x.destinationIndex ===
                                      property.destinationIndex
                                ) &&
                                !getMealPlanRatePlanMapping(
                                  room.id,
                                  property.destinationIndex
                                ).find(
                                  (x) =>
                                    x.value ===
                                    getMealPlanValueForRoom(
                                      property.destinationIndex
                                    )
                                )
                              ) && (
                                <span className="cancellation-status">
                                  {policyList.find(
                                    (x: any) =>
                                      x?.destinationIndex ===
                                        property.destinationIndex &&
                                      x?.roomId === room.id
                                  )?.text ?? ""}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })
                ) : (
                  <>No Rooms available</>
                )}
              </div>
            </div>
          ))}
        {propertyOverlay && (
          <PropertyOverlay
            onClosePropertyOverlay={setPropertyOverlay}
            newPropertyDetails={setPropertyDetails}
            opportunityStaysId={selectedPropertyId}
            removeRoomtype={setSelectRoomTypes}
            destinationIndex={selectedIndex}
            propertyId={selectedPropertyId}
            cityName={selectedCity!}
            loader={loader}
          />
        )}
      </div>
    );
  }
);

export default PackageItineraryStays;
